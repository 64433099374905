import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Autocomplete, Avatar, Badge, Button, Chip, IconButton, MenuItem, MenuList, Modal, Select, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import axios from "axios";
import moment from 'moment';
import { useSnackBar } from "../../providers/consumers/useSnackBar";
import { useBuilder } from "../../../hooks/useBuilder";
import { useDashboardDrawer } from "../../providers/consumers/useDashboardDrawer";
import store, { clear, setFieldState, setCommittenti } from "../../store/formStore";
import { AuthHelper } from "../../controllers/AuthHelper";
import EditIcon from '@mui/icons-material/Edit';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/it";
import { config, S3} from 'aws-sdk';
import { ServerConfig } from "../../../connectors/Config";
import { Buffer } from "buffer";
import Dropzone from 'react-dropzone';

import GzipHelper from "../../controllers/GzipHelper";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import { FileDownload, UploadFile } from "@mui/icons-material"
import { Stack } from "@mui/system";


const statuses_admin = [
    "DA VALIDARE BO",
    "PLICO NON RICEVUTO",
    "DA CARICARE",
    "RECUPERATO",
    "CONSEGNATO",
    "CARICATO",
    "NON VALIDATO",
    "KO",
    "KO AGENZIA",
  ];
  const statuses_bo_agenzia = [
    "DA VALIDARE BO",
    "PLICO NON RICEVUTO",
    "DA CARICARE",
    "RECUPERATO",
    "CARICATO",
    "NON VALIDATO",
    "KO AGENZIA",
    ];
    const statuses_agent = [
        "DA VALIDARE BO",
        "RECUPERATO",
        "NON VALIDATO"
    ];
    const statuses_agency = [
        "DA VALIDARE BO",
        "DA CARICARE",
        "RECUPERATO",
        "CONSEGNATO",
        "NON VALIDATO",
        "KO AGENZIA",
    ];
    

const excludeFromEdit = [
    'id',
    'status_maurizon',
    'Offerta',
    'updatedAt',
    'lead',
    'createdAt',
    'Committente',
    'Tipologia Cliente',
    'Competenza',
    'Note stato maurizon'
];

const excludeFromView = [
    'agency',
    'assigned',
    'POD',
    'PDR',
    'Nome cognome IBAN',
    'IBAN',
    'Competenza',
    'Note stato maurizon',
    'status_maurizon',
    'matricola'
]
const oggi = new Date();
const annoCorrente = oggi.getFullYear();
const mesiDellAnno = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre'
];

const arrayMesiAnnoCorrente = mesiDellAnno.map(mese => `${mese} ${annoCorrente}`);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '90vw' : 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

const authHelper = AuthHelper.getInstance();

const GetLead = (props) => {
    function a11yProps(index) {
        return {
          id: `lead-tab-${index}`,
          'aria-controls': `lead-tabpanel-${index}`,
        };
      }
    const params = useParams();

    const [tabValue, setTabValue] = React.useState(0);
    const [user, setUser] = React.useState(window.history.state.usr.user);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [lists, setLists] = React.useState([]);
    const [toggleVisualization, setToggleVisualization] = React.useState(true)
    const [detailsOpen, setDetailsOpen] = React.useState(false);
    const [detailsText, setDetailsText] = React.useState("");
    const [editNotesModal, setEditNotesModal] = React.useState(false);
    const [customers, setCustomers] = React.useState([]);
    const [userId, setUserId] = React.useState("");
    const [fieldToEdit, setFieldToEdit] = React.useState("");
    const [selectedPrivacy, setSelectedPrivacy] = React.useState("");
    const [users, setUsers] = React.useState([]);
    const [selectedContract, setSelectedContract] = React.useState("");
    const [assignOpen, setAssignOpen] = React.useState(false);
    const [matricola, setMatricola] = React.useState(null);
    const [sendTrigger, setSendTrigger] = React.useState(false);

    const [selectedContractObj, setSelectedContractObj] = React.useState({});

    useEffect(() => {
        console.log(selectedContractObj);

    }, [selectedContractObj])
    const [menuToShow, setMenuToShow] = React.useState("");

    const [contractExpand, setContractExpand] = React.useState(null);

    const [mobileEditLead, setMobileEditLead] = React.useState(false);

    const [note, setNote] = React.useState({
        title: null,
        body: null
    });
    const { addAlert } = useSnackBar();

    const {builder, form, refs} = useBuilder();
    const { pushMobileOpen, defaultOpen, addRouter } = useDashboardDrawer();

    const [selectVendorOpen, setSelectVendorOpen] = React.useState(false);

    const [role, setRole] = React.useState("");

    const [committentiSottrazione, setCommittentiSottrazione] = React.useState([]);

    const [dateFilter, setDateFilter] = React.useState({
        from: '',
        to: ''
    })
    function filterNullValues(obj) {
        return Object.keys(obj).reduce((result, key) => {
          if (obj[key] !== null) {
            result[key] = obj[key];
          }
          return result;
        }, {});
      }
    const fetchLists = async() => {
        const res = await fetch('https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/sib/lists');
        const unzipped = await GzipHelper.unzip(await res.json())
        setLists([...unzipped.lists]);
    }
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const getCustomers = async() => {
        const res = await axios.get('https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/customer');
        const unzipped = await GzipHelper.unzip(res.data)
        setCustomers([...unzipped]);
        store.dispatch([...unzipped])
        setLoading(false);
    }
   
    const getUsers = async() => {
        const authIstance = AuthHelper.getInstance();
        const _users = await await AuthHelper.getUsers();
        if(_users){
          const _remap = [..._users.map(x => x.Attributes.map(y => ({[y.Name]: y.Value})))];
          const _usersRemapped = [];
          for(let _remapped of _remap){
              const _single_user = {};
              for(let object of _remapped){
                  _single_user[Object.keys(object)[0]] = object[Object.keys(object)[0]];
                  if(Object.keys(object)[0] === 'sub'){
                      _single_user.id = object.sub;
                  }
              }
              _usersRemapped.push(_single_user);
          }
          setUsers([..._usersRemapped.filter(x => (x['custom:roles'] === 'OPERATORE BO' || x['custom:roles'] === 'ADMIN'))]);
          setLoading(false);
        }
    }
    React.useEffect(() => {
        builder('interests.add');
        if(window.history.state.usr.user.lead){
            getLead(window.history.state.usr.user.lead);
        }else{
            console.log('id', window.history.state.usr.user.lead);
            getLead(window.history.state.usr.user.id);
        }
        fetchLists();
        setUser({...window.history.state.usr.user});
        setInterval(() => {
            if(window.history.state.usr.user.lead){
                getLead(window.history.state.usr.user.lead, true);
            }else{
                console.log('id', window.history.state.usr.user.lead);
                getLead(window.history.state.usr.user.id, true);
            }
        }, 30000);
        getUsers();
        getCustomers();
        getRoleMenu();
        getRole();
        committenti();


        AuthHelper.getUserFromIdToken().then((res) => setUserId(res.UserAttributes.filter(x => x.Name === 'sub')[0].Value.split('-')[0]));

    }, []);


    const getRoleMenu = async() => {
        let user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
        const attributes = await AuthHelper.getUserFromIdToken();

        sessionStorage.setItem('attributes', JSON.stringify(attributes));

        setMenuToShow(user ? user.Attributes.filter(x => x.Name === 'custom:company')[0].Value : attributes.UserAttributes.filter(x => x.Name === 'custom:company')[0].Value);

    }
    const getOperation = async(id) => {
        const attributes = await AuthHelper.getUserFromIdToken();
        const res = await fetch(`https://sf9z3301hf.execute-api.eu-west-1.amazonaws.com/dev/api/v1/user/op/${attributes.Username}`);
        let json = await res.json();
        return json;
    }
    const getLead = async(id, privacy = false) => {
        const res = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/lead/${id}`);
        let json = await res.json();
        const unzipped = await GzipHelper.unzip(json)
        if(privacy){

        }else{
            setUser({...unzipped});
            sessionStorage.setItem('privacies', JSON.stringify(unzipped.privacies));
        }

    }
    const assignContracts = async() => {
        setLoading(true);
        try{
          const body = {
            contracts: [selectedContract],
            assignerId: (await AuthHelper.getUserFromIdToken()).UserAttributes.filter(x => x.Name === 'sub')[0].Value.split('-')[0],
            matricola: matricola.sub,
            'custom:vendor': matricola['custom:vendor']
          };

          const res = await fetch('https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contracts/assign', {body: JSON.stringify(body), method: 'PUT'});

          setLoading(false);
          setOpen(false);
          addAlert({
            message: 'Il contratto è stato modificato',
            severity: 'success'
          });
          window.location.reload();
        }catch(ex){
          setOpen(false);
          setLoading(false);
          addAlert({
            message: 'Errore server. Riprova più tardi o contatta il reparto tecnico.',
            severity: 'error'
          });
        }


      }
    const updateLead = async() => {
        try{
            setLoading(true);
            const res = await axios.put(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/lead/${params.id}`, JSON.stringify({...user, matricola: userId, email: user.email.toUpperCase(), phone: user.phone.replace('+39', '')}));
            setLoading(false);
            addAlert({
                message: 'Il lead è stato modificato',
                severity: 'success'
            })
            window.location.reload();
            // setUser({...res.data});
        }catch(ex){
            setLoading(false);
            addAlert({
                message: 'Errore server',
                severity: 'error'
            })
        }

    }

    const sendSMS = async(committente, otp) => {
        let sent = sessionStorage.getItem('sent') ? JSON.parse(sessionStorage.getItem('sent')) : {};
        // sent = {};
        if(sent[params.id]){
            if(sent[params.id].includes(committente)){
                addAlert({
                    message: 'Hai già inviato un SMS per questo Lead',
                    severity: 'error'
                });
                return;
            }
        }
        // if(sent.includes(params.id)){
        //     addAlert({
        //         message: 'Hai già inviato un SMS per questo Lead',
        //         severity: 'error'
        //     });
        //     return;
        // }
        try{
            // if(user.privacies){
            //     if(!user.privacies?.some(x => !x[Object.keys(x)[0]].accepted)){
            //         addAlert({
            //             message: 'Tutte le privacy sono ancora valide, non puoi inviare l\'sms.',
            //             severity: 'error'
            //         });
            //         return;
            //     }
            // }
            // console.log(committente, Object.keys(user.privacies));

            if(user.privacies && Object.keys(user.privacies).filter(x => x === committente)[0]){
                if(getPrivacyAccepted(committente)){
                    addAlert({
                        message: 'La privacy per la committente è ancora attiva',
                        severity: 'error'
                    });
                    return;
                }

            }
            const resOperation = await getOperation();
            if(resOperation.message[0]?.operation !== "" && resOperation.message[0]?.operation !== null){
                const fubRes = await (await fetch(`https://lead4life.kaori.group/api/v1/${resOperation.message[0]?.operation}/${user?.phone}`, {
                    headers: {
                        Authorization: `Bearer 14|IVAG8cJ0HKlap3xEpkegxLBpR2A85qkcaP8Zkj5K`
                    },
                })).json();

                if(fubRes.message === 'KO'){
                    //Dobbiamo far inviare una mail di alert

                    alert('Il numero non è presente nella lista');
                    return;
                }
            }
            // //FUBBO
           

            setLoading(true);
            if(sent[params.id])sent[params.id] = [...sent[params.id],
                committente
            ]
            else{
                sent[params.id] = [committente]
            }
            // sent.push(params.id);
            setSendTrigger(true)

            sessionStorage.setItem('sent', JSON.stringify(sent));

            const res = await axios.put(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/twilio/send/${params.id}`, JSON.stringify({matricola: userId, committente: committente, otp}));
            setUser({...res.data});
            addAlert({
                message: 'SMS Inviato.',
                severity: 'success'
            })
            setLoading(false);
        }catch(ex){
            console.log(ex);
            setLoading(false);
            addAlert({
                message: 'Errore server',
                severity: 'error'
            })
        }
    }

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
          backgroundColor: '#44b700',
          color: '#44b700',
          boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
          '&::after': {
            position: 'absolute',
            top: -1.2,
            left: -1.2,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
          },
        },
      }));
      const fileConverterBase64 = (file) => {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                res(reader.result)
            }
            reader.onerror = (err) => {
                rej(err)
            }
        })
    }
      const softDelete = async() => {
        
        try{
          const resDelete = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/lead/soft-delete/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify({operatore: userId})
          });
          addAlert({
            message: 'Il lead è stato cancellato soft',
            severity: 'success'
          });
          setLoading(false);

        }catch(ex){
          addAlert({
            message: 'Errore server',
            severity: 'error'
          });
          setLoading(false);
        }
      }
    const handleClick = () => {
            store.dispatch(clear())
            if(store.getState().elements.length === 0){
                builder('interests.add');
            }
            store.dispatch((setFieldState({name: 'id', value: user.id})));

            window.setTimeout(() => pushMobileOpen(true, 500, "Aggiorna Interessi", null, refs), 200)

    }
    const handleContractAdd = () => {
        store.dispatch(clear())
        if(store.getState().elements.length === 0){
            builder('contracts.add');
        }
        store.dispatch((setFieldState({name: 'id', value: user.id})));
        store.dispatch((setFieldState({name: 'denominazione', value: `${user?.first_name} ${user?.last_name}`})))
        store.dispatch((setFieldState({name: 'phone', value: `${user?.phone}`})))
        window.setTimeout(() => pushMobileOpen(true, 500, "Aggiungi contratto", null, refs), 200)

    }
    const handleSignalsAdd = () => {
        store.dispatch(clear())
        if(store.getState().elements.length === 0){
            builder('segnalazioni.add');
        }
        store.dispatch((setFieldState({name: 'id', value: user.id})));
        store.dispatch((setFieldState({name: 'denominazione', value: `${user?.first_name} ${user?.last_name}`})))
        store.dispatch((setFieldState({name: 'phone', value: `${user?.phone}`})));
        window.setTimeout(() => pushMobileOpen(true, 500, "Aggiungi Segnalazione", null, refs), 200)

    }
    const handleCaldaiaQuestions = () => {
        store.dispatch(clear())
        if(store.getState().elements.length === 0){
            builder('caldaia.add');
        }
        store.dispatch((setFieldState({name: 'id', value: user.id})));
        window.setTimeout(() => pushMobileOpen(true, 500, "Aggiungi Caldaia", null, refs), 200)

    }
    const handleClimaQuestions = () => {
        store.dispatch(clear())
        if(store.getState().elements.length === 0){
            builder('clima.add');
        }
        store.dispatch((setFieldState({name: 'id', value: user.id})));
        window.setTimeout(() => pushMobileOpen(true, 500, "Aggiungi Clima", null, refs), 200)

    }
    const addNotes = async() => {
        const res = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/lead/notes/${user.id}`, {
            method: 'PUT',
            body: JSON.stringify({
                ...note,
                matricola: userId,
                date: moment().toLocaleString('it-IT')
            })
        });
        addAlert({
            message: `Nota inserita con successo`,
            severity: 'success'
        })
        window.setTimeout(() => window.location.reload(), 1000)

    }
    const addToList = async(x) => {
        const res = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/sib/add/${user.id}`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        email: user.email,
                        listName: x.name,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        idList: x.id
                    })
        });
        addAlert({
            message: `Lead inserito nella lista ${x.name}`,
            severity: 'success'
        })
        setOpen(false);
        window.location.reload();
    }
    const openAddContract = (user) => {
        store.dispatch(clear())
        // builder('contracts.add');
        store.dispatch(clear())

        if(store.getState().elements.length === 0){
            builder('contracts.add');
        }
        store.dispatch((setFieldState({name: 'id', value: user.lead})));
        store.dispatch((setFieldState({name: 'Denominazione', value: `${user.Denominazione}`})));
        store.dispatch((setFieldState({name: 'P.IVA', value: `${user['P.IVA']}`})));
        store.dispatch((setFieldState({name: 'Committente', value: `${user?.Committente}`})));
        store.dispatch((setFieldState({name: 'Tipologia Cliente', value: `${user['Tipologia Cliente']}`})))
        if(user['Tipologia Cliente'] === 'Consumer'){
            store.dispatch((setFieldState({name: 'Indirizzo', value: `${user['Indirizzo']}`})))
            store.dispatch((setFieldState({name: 'CAP', value: `${user['CAP']}`})))
            store.dispatch((setFieldState({name: 'Provincia', value: `${user['Provincia']}`})))
            store.dispatch((setFieldState({name: 'Bolletta Web', value: `${user['Bolletta Web']}`})))
        }else{
            store.dispatch((setFieldState({name: 'Indirizzo societa', value: `${user['Indirizzo societa']}`})))
            store.dispatch((setFieldState({name: 'CAP societa', value: `${user['CAP societa']}`})))
            store.dispatch((setFieldState({name: 'Provincia societa', value: `${user['Provincia societa']}`})))
            store.dispatch((setFieldState({name: 'Bolletta Web', value: `${user['Bolletta Web']}`})))
            store.dispatch((setFieldState({name: 'Ragione Sociale', value: `${user['Ragione Sociale']}`})));
        }
        pushMobileOpen(true, 500, "Aggiungi contratto", null, refs);

        window.setTimeout(async () => {
            try{
              console.log(`contracts.subschema.committenti.${user?.Committente.toLowerCase().replaceAll(' ', '_').replaceAll('.', '_')}`);
              
              await builder(`contracts.subschema.committenti.${user?.Committente.toLowerCase().replaceAll(' ', '_').replaceAll('.', '_')}`);
            }catch(ex){
              await builder(`contracts.fallback`);
            }
            window.setTimeout(() => {
                
              const checkboxes = [...document.querySelectorAll('[name="radio-buttons-group"]')];
              console.log(user);
    
              if(user['Tipologia Cliente'] === 'Business'){
                checkboxes.filter(checkbox => checkbox.defaultValue === 'Business')[0].click();
              }else{
                checkboxes.filter(checkbox => checkbox.defaultValue === 'Consumer')[0].click();
              }
              
            }, 200)
          }, 1000)

      }
    const committenti = async () => {
        const committenti = await AuthHelper.getCommittenti();
        console.log('COMMITTENTI', committenti);
        setCommittentiSottrazione([...committenti]);
    }
    const getDetailsText = (details, isFormClima) => {
        let text = "";
        if(details){
            if(details.consents){
                text += `${details.consents}`
            }
            if(details.previous){
                const previous = typeof details.previous === 'string' ? JSON.parse(details.previous) : details.previous;
                console.log(details.previous);

                if(previous.last_name){
                    text += '<strong>Anagrafica precedente: </strong><br>'
                    text += `Email: ${previous.email} <br> Nome: ${previous.first_name} <br> Cognome: ${previous.last_name} <br/> Telefono: ${previous.phone}`
                }
                if(previous.Committente){
                    text += '<strong>Contratto precedente: </strong><br>'

                    for(let key of Object.keys(previous)){
                        text += `<strong>${key}:</strong> <span>${previous[key]}</span><br/>`
                    }
                }
                if(Object.prototype.toString.call(previous) === '[object Array]'){
                    text += `<br> <br> Privacy Precedente: <br> ${details.previous.map(x => `<strong>${Object.keys(x)[0]}</strong>: ${x[Object.keys(x)[0]].accepted ? 'Accettato': 'Non accettato'}`).join('<br>')}`;
                    text += `<br><br> <strong>IP</strong>: ${details.ip} <br> <strong>Committente</strong>: ${details.customer}`
                }
            }

            if(details.previousInterests){
                text += `Interessi precedenti:<br> ${Object.keys(details.previousInterests).map(x => x !== 'id' ? `<strong>${x}</strong>: ${details.previousInterests[x]}` : undefined).join('<br>')}`
            }

            if(isFormClima){
                for(let key in details){
                    console.log(key);
                    if(key !== 'matricola' && key !== 'id'){
                        text += `<strong>${key}:</strong> <span>${details[key]}</span><br />`
                    }
                }
            }

            if(text === '')setDetailsOpen(false);
        }

        return text;
    }

    const dispatchChipsAction = async(value) => {
        console.log(value);
        if(value === 'caldaia') handleCaldaiaQuestions();
        else if(value === 'clima')handleClimaQuestions();
        else handleContractAdd();
    }

    const editContractField = (index, key, value) => {
        let contracts = [...user.contracts];
        let contract = {...contracts[index]};
        contract[key] = value;
        contracts[index] = contract;
        let _user = {...user};
        _user.contracts = contracts;
        setUser({..._user});
    }

    const getClimaOrCaldaia = (clima) => {
        console.log(user.clima)
        return getDetailsText(clima ? user.clima : user.caldaia, true);
    }
    const getPrivacyAccepted = (committente) => {

        let keys = user.privacies[committente];

        let singleObjectKey = Object.keys(keys).filter(x => x !== 'ip')

        const acceptedPrivacies = singleObjectKey.map(x => keys[x][Object.keys(keys[x])[0]]).map(x => x.accepted)

        const isAccepted = acceptedPrivacies.some(x => x === false)

        return !isAccepted;

    }
    const isConsent = () => {
        return user.privacies ? Object.keys(user.privacies).map(x => getPrivacyAccepted(x)) : [];
    }

    const getRole = async() => {
        const attributes = await AuthHelper.getUserFromIdToken();
        setRole(attributes.UserAttributes.filter(x => x.Name === 'custom:roles')[0].Value);
    }

    const expandContract = async(i) => {
        if(contractExpand === i){
            setContractExpand(null);
            return;
        }
        const res = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contract/${user.contracts[i].id}`);

        const unzipped = await GzipHelper.unzip(await res.json())

        setSelectedContractObj({...unzipped});
        setContractExpand(i);
        return;
    }
    return (<Grid container spacing={2}>
        <Modal
        open={assignOpen}
        onClose={() => setAssignOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Scegli un utente
          </Typography>
          <Autocomplete
              id="user-selection"
              sx={{ width: 300, mt: 2 }}
              options={users}
              autoHighlight
              getOptionLabel={(option) => `${option.name} ${option.family_name}`}
              value={matricola}
              onChange={(e, newValue) => setMatricola(newValue)}
              renderOption={(props, option) => {
                return (
                  <MenuItem {...props}>{props.key}</MenuItem>
                );
              }}
              renderInput={(params) => (
                  <TextField
                  {...params}
                  label="Seleziona un utente"
                  />
              )}
              />
          {/* <MenuList>
            {users.map(x =>
                <MenuItem onClick={() => addToList(x)}>{x.name}</MenuItem>
            )}
          </MenuList> */}
          <div style={{display: 'flex', marginTop: 12, justifyContent: 'space-between'}} ><LoadingButton variant="contained" color="primary" onClick={() => assignContracts()} loading={loading}>Assegna</LoadingButton><LoadingButton variant="contained" color="secondary" onClick={() => setOpen(false)} loading={loading}>Chiudi</LoadingButton></div>
        </Box>
      </Modal>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Scegli una lista
                </Typography>

                <MenuList>

                    {lists.map(x =>
                        <MenuItem onClick={() => addToList(x)}>{x.name}</MenuItem>
                    )}
                </MenuList>
                 <Button variant="contained" color="secondary" sx={{mt:2}} onClick={() => setOpen(false)}>Chiudi</Button>

                </Box>


            </Modal>
            <Modal
                open={detailsOpen}
                onClose={() => setDetailsOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography><div dangerouslySetInnerHTML={{__html: detailsText}} /></Typography>

                    <Button variant="contained" color="secondary" sx={{mt:2}} onClick={() => setDetailsOpen(false)}>Chiudi</Button>

                </Box>

            </Modal>

            <Modal
                open={editNotesModal}
                onClose={() => setEditNotesModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Typography variant="h4">Inserisci nota</Typography> <br/>
                <TextField label="Titolo" variant="standard"  InputLabelProps={{
                                sx: {
                                    color: "#444",
                                  },
                                shrink: true
                            }}
                            fullWidth
                            value={note.title}
                            inputProps={{style: {color: '#000'}}}
                            style={{color: '#000'}}
                            onChange={(e) => setNote({...note, title: e.target.value})}
                            sx={{color: '#000', marginBottom: 1,marginTop: '1rem'}} />
                <TextField label="Corpo" variant="standard"  InputLabelProps={{
                                sx: {
                                    color: "#444",
                                  },
                                shrink: true
                            }}
                            fullWidth
                            value={note.body}
                            inputProps={{style: {color: '#000'}}}
                            style={{color: '#000'}}
                            onChange={(e) => setNote({...note, body: e.target.value})}
                            multiline
                            maxRows={3}
                            sx={{color: '#000', marginBottom: 1,marginTop: '1rem'}} /> <br/>
                <Button variant="contained" color="primary" onClick={() => addNotes()}>Salva nota</Button>
                </Box>
            </Modal>

            <Modal
                open={selectVendorOpen}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Scegli una committente
                </Typography>
                <MenuList>
                    {customers.filter(x => x['Visibile Lead'] === 'S').filter(x => x['status'] === 'S').filter(x => !committentiSottrazione.includes(x.name.toUpperCase().trim())).map(x =>
                        <MenuItem disabled={loading} onClick={() => sendSMS(x.name, x['otp'] === 'S')}>{x.name}</MenuItem>
                    )}
                </MenuList>

                <Button variant="contained" color="secondary" sx={{mt:2}} onClick={() => setSelectVendorOpen(false)}>Chiudi</Button>

                </Box>
            </Modal>
        <Grid sx={{
            display: {
                xs: 'none',
                md: 'block'
            }
        }} item xs={12} md={3}>
          <div style={{display: 'flex',  flexDirection: 'column', background: '#fff', borderRight: '1px solid rgb(203, 214, 226)', width: '100%', minHeight: '100vh', position: 'sticky', top: 0}}>
            <div style={{paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '1rem', display: 'flex', paddingBottom: '1rem', borderBottom: '1px solid rgb(203, 214, 226)', alignItems: 'center'}}>
            {isConsent().some((x) => x === true) ? <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                >
                    <Avatar sx={{backgroundColor: 'rgb(81, 111, 144)', width: 85, height: 85, fontSize: 32}}>{`${user?.first_name && user?.first_name[0]}${user?.last_name && user.last_name[0]}`}</Avatar>
                </StyledBadge> : <Avatar sx={{backgroundColor: 'rgb(81, 111, 144)', width: 85, height: 85, fontSize: 32}}>{`${user?.first_name && user.first_name[0]}${user?.last_name && user.last_name[0]}`}</Avatar>
            }
                <div style={{display: 'block', textAlign: 'left', paddingLeft: '2rem', maxWidth: 200, lineBreak: 'anywhere'}}>
                    <Typography fontWeight="bold" color="#8e8e8e" fontSize="1.3rem">{`${user?.first_name} ${user?.last_name}`}</Typography>
                    <Typography fontWeight="600" color="#000" fontSize="0.75rem">{user?.email}</Typography>
                    <div style={{display: 'flex', paddingTop: '1rem', gap: '0.5rem'}}>
                        <div onClick={() => setSelectVendorOpen(true)} style={{width: 32, height: 32, fontSize: 18, borderRadius: '100%', backgroundColor: 'rgb(46, 63, 80)', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff'}} className="icon-button">
                            <SmsIcon fontSize="24px" />
                        </div>
                        <div onClick={() => setOpen(true)} style={{width: 32, height: 32, fontSize: 18, borderRadius: '100%', backgroundColor: 'rgb(46, 63, 80)', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff'}} className="icon-button">
                            <EmailIcon fontSize="24px" />
                        </div>
                        {/* <div style={{width: 32, height: 32, fontSize: 18, borderRadius: '100%', backgroundColor: 'rgb(46, 63, 80)', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff'}} className="icon-button">
                            <PhoneIcon fontSize="24px" />
                        </div> */}
                    </div>
                </div>
            </div>

            <div style={{display: 'flex', paddingTop: '1rem', paddingRight: '1rem', paddingLeft: '1rem', flexDirection: 'column', textAlign: 'left'}}>
                <Button variant="contained" color="primary" onClick={() => setSelectVendorOpen(true)} disabled={role === 'TEAM LEADER AGENZIA'}>Invia sms</Button>

                <Typography fontWeight='bold' color="rgb(46, 63, 80)" marginTop={1} marginBottom={1}>Stato lead: <Typography color='#000'>
                    <Select  sx={{color: '#000'}} fullWidth value={selectedPrivacy} onChange={(e) => setSelectedPrivacy(e.target.value)}>
                        <MenuItem value=""></MenuItem>
                        {user.privacies && Object.keys(user.privacies).map(x => (
                            <MenuItem value={x}>{x}</MenuItem>
                        ))}
                    </Select>
                    {
                        user.privacies &&
                        selectedPrivacy !== "" &&
                        Object.keys(user.privacies[selectedPrivacy]).filter(x => x !== "ip").map(x => (
                            <div><strong>{Object.keys(user.privacies[selectedPrivacy][x])[0]}</strong>: <ul><li>{user.privacies[selectedPrivacy][x][Object.keys(user.privacies[selectedPrivacy][x])[0]].accepted ? 'Accettato' : 'No'}</li><li>Scadenza: {moment(user.privacies[selectedPrivacy][x][Object.keys(user.privacies[selectedPrivacy][x])[0]].expiry).format('DD/MM/YYYY HH:mm')}</li><li>IP: {user.privacies[selectedPrivacy]['ip']}</li></ul></div>
                        ))
                        // {x[Object.keys(x)[0]].accepted ? 'Accettato' :  'No'} - Scadenza: {moment(x[Object.keys(x)[0]].expiry).format('DD/MM/YYYY HH:mm')}
                        // Object.keys(user.privacies[selectedPrivacy]).map(x =>
                        //     null
                        //     // <div style={{fontSize: 11}}><strong>{Object.keys(user.privacies[selectedPrivacy][x])[0]}:</strong><p></p></div>
                        // )
                    }
                {/* {user.privacies?.map(x => <div style={{fontSize: 11}}><strong>{Object.keys(x)[0]}:</strong><p>{x[Object.keys(x)[0]].accepted ? 'Accettato' :  'No'} - Scadenza: {moment(x[Object.keys(x)[0]].expiry).format('DD/MM/YYYY HH:mm')}</p></div>)} */}
                </Typography></Typography>
                <Typography fontWeight='bold' color="rgb(46, 63, 80)">Anagrafica</Typography>
                <TextField label="Email" variant="standard" disabled={(isConsent().some((x) => x === true))} InputLabelProps={{
                                sx: {
                                    color: "#444",
                                  },
                                shrink: true
                            }}
                            value={user.email}
                            inputProps={{style: {color: '#000'}}}
                            style={{color: '#000'}}
                            onChange={(e) => setUser({...user, email: e.target.value})}
                            sx={{color: '#000', marginBottom: 1,marginTop: '1rem'}} />
                <TextField label="Nome" variant="standard" disabled={(isConsent().some((x) => x === true))} InputLabelProps={{
                            sx: {
                                color: "#444",
                                },
                            shrink: true
                        }}
                        onChange={(e) => setUser({...user, first_name: e.target.value})}
                        value={user.first_name}
                        inputProps={{style: {color: '#000'}}}
                        style={{color: '#000'}}
                        sx={{color: '#000', marginBottom: 1}} />
                <TextField label="Cognome" variant="standard" disabled={(isConsent().some((x) => x === true))} InputLabelProps={{
                            sx: {
                                color: "#444",
                                },
                            shrink: true
                        }}
                        inputProps={{style: {color: '#000'}}}
                        style={{color: '#000'}}
                        onChange={(e) => setUser({...user, last_name: e.target.value})}
                        value={user.last_name}
                        sx={{color: '#000', marginBottom: 1}} />
                <TextField label="Telefono" variant="standard" disabled={(isConsent().some((x) => x === true))} InputLabelProps={{
                                sx: {
                                    color: "#444",
                                },
                                shrink: true
                            }}
                            value={user.phone}
                            inputProps={{style: {color: '#000'}}}
                            style={{color: '#000'}}
                            onChange={(e) => setUser({...user, phone: e.target.value.replaceAll(' ', '').trim()})}
                            sx={{color: '#000', marginBottom: 1}} />

                <LoadingButton onClick={() => updateLead()} fullWidth variant="contained" loading={loading} disabled={role === 'TEAM LEADER AGENZIA' || sendTrigger || sessionStorage.getItem('sent')}>Aggiorna Lead</LoadingButton>
                <br/>
                <br/>
                <LoadingButton onClick={() => softDelete()} disabled={role !== 'ADMIN' && role !== 'OPERATORE BO'} fullWidth variant="contained" loading={loading}>Elimina lead</LoadingButton>


            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
            <MobileView>
                    <h4>{user.first_name} {user.last_name}</h4>
                    <p style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3}}><Box sx={{width: 10, height: 10, backgroundColor: isConsent().some((x) => x === true) ? '#44b700' : '#eee', borderRadius: '100%'}}></Box>Stato lead: {isConsent().some((x) => x === true) ? <strong style={{color: '#44b700'}}>Consensato</strong> : <i>Non consensato</i>}</p>

                    <Button fullWidth variant="contained" onClick={handleContractAdd} sx={{mt: 2, ml: 2, mr: 2, width: '50%'}} disabled={false}>Aggiungi contratto</Button>
                    <Button fullWidth variant="contained" sx={{mt:2, ml: 2, mr: 2, width: '50%'}} onClick={() => setSelectVendorOpen(true)}>Invia SMS</Button>

                    <Button color="secondary" variant="contained" sx={{mt:6, mb: mobileEditLead ? 1 : 6,  width: '50%'}} onClick={() => mobileEditLead ? setMobileEditLead(false) : setMobileEditLead(true)}>{mobileEditLead ? 'Nascondi' : 'Modifica anagrafica'}</Button>

                    {mobileEditLead && <>
                        <Typography fontWeight='bold' color="rgb(46, 63, 80)" sx={{mt: 3}}>Anagrafica</Typography>
                    <Grid container>
                        <Grid xs={12}>
                            <TextField label="Email" variant="standard" disabled={(isConsent().some((x) => x === true))} InputLabelProps={{
                                        sx: {
                                            color: "#444",
                                        },
                                        shrink: true
                                    }}
                                    value={user.email}
                                    inputProps={{style: {color: '#000'}}}
                                    style={{color: '#000'}}
                                    onChange={(e) => setUser({...user, email: e.target.value})}
                                    sx={{color: '#000', marginBottom: 1,marginTop: '1rem'}} />
                        </Grid>
                        <Grid xs={12}>
                            <TextField label="Nome" variant="standard" disabled={(isConsent().some((x) => x === true))} InputLabelProps={{
                                    sx: {
                                        color: "#444",
                                        },
                                    shrink: true
                                }}
                                onChange={(e) => setUser({...user, first_name: e.target.value})}
                                value={user.first_name}
                                inputProps={{style: {color: '#000'}}}
                                style={{color: '#000'}}
                                        sx={{color: '#000', marginBottom: 1}} />
                        </Grid>
                        <Grid xs={12}>
                            <TextField label="Cognome" variant="standard" disabled={(isConsent().some((x) => x === true))} InputLabelProps={{
                                    sx: {
                                        color: "#444",
                                        },
                                    shrink: true
                                }}
                                inputProps={{style: {color: '#000'}}}
                                style={{color: '#000'}}
                                onChange={(e) => setUser({...user, last_name: e.target.value})}
                                value={user.last_name}
                                sx={{color: '#000', marginBottom: 1}} />
                        </Grid>
                        <Grid xs={12}>
                            <TextField label="Telefono" variant="standard" disabled={(isConsent().some((x) => x === true))} InputLabelProps={{
                                            sx: {
                                                color: "#444",
                                            },
                                            shrink: true
                                        }}
                                        value={user.phone}
                                        inputProps={{style: {color: '#000'}}}
                                        style={{color: '#000'}}
                                        onChange={(e) => setUser({...user, phone: e.target.value.replaceAll(' ', '').trim()})}
                                        sx={{color: '#000', marginBottom: 1}} />
                        </Grid>
                        <Grid xs={12}>
                            <LoadingButton onClick={() => updateLead()} fullWidth sx={{width: '50%', mt: 2}} variant="contained" loading={loading} disabled={role === 'TEAM LEADER AGENZIA' || sendTrigger || sessionStorage.getItem('sent')}>Aggiorna Lead</LoadingButton>
                        </Grid>
                    </Grid>
                    </>}




            </MobileView>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Eventi" sx={{color: 'rgb(46, 63, 80)', fontWeight: 'bold'}} {...a11yProps(0)} />
                    <Tab color="#000" sx={{color: 'rgb(46, 63, 80)', fontWeight: 'bold'}} label="Note" {...a11yProps(1)} />
                    {menuToShow === 'Fidasi' && <Tab color="#000" sx={{color: 'rgb(46, 63, 80)', fontWeight: 'bold'}} label="Contratti" {...a11yProps(2)} />}
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                 <div style={{display: 'flex', flexDirection: 'column', gap: 8, paddingTop: 12, paddingBottom: 12}}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                        <ToggleButtonGroup
                            value={toggleVisualization}
                            exclusive
                            color="primary"
                            onChange={(e, value) => setToggleVisualization(value)}
                        >
                            <ToggleButton value={true}>
                                Timeline
                            </ToggleButton>
                            <ToggleButton value={false}>
                                Card
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <BrowserView>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
                                    <DatePicker value={dateFilter.from !== '' ? dateFilter.from : new Date()} onChange={(e) => setDateFilter({...dateFilter, from: e})} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, mr: 2}}/>} />
                                </LocalizationProvider>
                                -
                                <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it">
                                    <DatePicker value={dateFilter.to !== '' ? dateFilter.to : new Date()} onChange={(e) => setDateFilter({...dateFilter, to: e})} renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, ml: 2}}/>} />
                                </LocalizationProvider>
                            </div>
                        </BrowserView>
                    </div>
                    {toggleVisualization ?
                    <Timeline position="alternate">
                            {user?.events?.sort((a,b) => moment(b.date).unix() - moment(a.date).unix()).filter(x => dateFilter.from !== "" ? moment(x.date).subtract(dateFilter.from).unix() > -24000 : true).filter(x => dateFilter.to !== "" ? moment(x.date).subtract(dateFilter.to).unix() < 24000 : true).map(x => (
                                <TimelineItem sx={{ minWidth: 275 }} key={x.date}>
                                        <TimelineSeparator>

                                            <TimelineDot  onClick={() => {
                                                if(isBrowser){
                                                    setDetailsText(x.title === 'Form Clima' || x.title === 'Form Caldaia' ? getClimaOrCaldaia(x.title === 'Form Clima') : getDetailsText(x.details)); getDetailsText(x.details) !== '' && setDetailsOpen(true)
                                                }
                                            }}/>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <strong>{x.title}</strong>
                                            {x.matricola && <p>Matricola: {x.matricola}</p>}
                                            <p>{moment(x.date).format('DD/MM/YYYY HH:mm')}</p>
                                            {x.ip && <strong>ip: {x.ip}</strong>}
                                            {x.title === 'Aggiunta contratto' && <Button variant="outlined" onClick={async () => {
                                                store.dispatch(clear())
                                                const contract = JSON.parse(x.details.previous);
                                               
                                                openAddContract(contract);
                                            }}>Duplica</Button>}

                                        </TimelineContent>
                                </TimelineItem>
                            ))}
                    </Timeline>
                    :  user.events.sort((a,b) => moment(b.date).unix() - moment(a.date).unix()).filter(x => dateFilter.from !== "" ? moment(x.date).subtract(dateFilter.from).unix() > -24000 : true).filter(x => dateFilter.to !== "" ? moment(x.date).subtract(dateFilter.to).unix() < 24000 : true).map(x => (
                        <Card sx={{ minWidth: 275 }} key={x.date} onClick={() => {setDetailsText(getDetailsText(x.details)); getDetailsText(x.details) !== '' && setDetailsOpen(true)}}>
                            <CardContent sx={{textAlign: 'left'}}>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Typography variant="h5" color="#3f3f3f" component="div">
                                        {x.title}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="#3f3f3f" gutterBottom>
                                        {moment(x.date).format('DD/MM/YYYY HH:mm')}
                                    </Typography>
                                </div>

                                <Typography variant="body2" color="#3f3f3f" marginTop={2}>
                                    <div dangerouslySetInnerHTML={{__html: x.text}} />
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}


                </div>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant="contained" onClick={() => setEditNotesModal(true)}>Aggiungi nota</Button>
                </div>
                {user.notes ? user.notes.sort((a,b) => moment(b.date).unix() - moment(a.date).unix()).map(x => <Card sx={{ minWidth: 275, mb: 2 }} key={x.date} onClick={() => {setDetailsText(getDetailsText(x.details)); setDetailsOpen(true)}}>
                            <CardContent sx={{textAlign: 'left'}}>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Typography variant="h5" color="#3f3f3f" component="div">
                                        {x.title}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="#3f3f3f" gutterBottom>
                                        {x.date ? moment(x.date).format('DD/MM/YYYY HH:mm') : null}<br />
                                    </Typography>
                                </div>

                                <Typography variant="body2" color="#3f3f3f" marginTop={2}>
                                    <div dangerouslySetInnerHTML={{__html: x.body}} />
                                </Typography>
                            </CardContent>
                        </Card> ): <p>Nessuna Nota</p>}

            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                {user.contracts ? user.contracts.sort((a,b) => moment(b.date).unix() - moment(a.date).unix()).map((x, i) => <Card sx={{ minWidth: 275, mb: 2 }} key={x.date} onClick={() => {setDetailsText(getDetailsText(x.details)); detailsText !== '' && setDetailsOpen(true)}}>
                            <CardContent sx={{textAlign: 'left'}}>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <Typography variant="h5" color="#3f3f3f" component="div">
                                        {x.Committente}
                                    </Typography>
                                    <Typography sx={{ fontSize: 14 }} color="#3f3f3f" gutterBottom>
                                        {x.createdAt ? moment(x.createdAt).format('DD/MM/YYYY HH:mm') : null}<br />
                                    </Typography>
                                </div>
                                { contractExpand === i && selectedContractObj && Object.keys(selectedContractObj).filter(x => x === 'POD' || x === 'PDR').filter(x => selectedContractObj[x]).map(y => <Typography variant="body2" color="#3f3f3f" marginTop={2} sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}>
                                        <strong>{y}</strong>:

                                        {fieldToEdit === y ? <TextField variant="outlined"  size="small" InputLabelProps={{
                                                                                                            sx: {
                                                                                                                color: "#444",

                                                                                                            },
                                                                                                            shrink: true
                                                                                                        }}
                                                                value={user.contracts[i][y]}
                                                                inputProps={{style: {color: '#000'}}}
                                                                style={{color: '#000'}}
                                                                onChange={(e) => editContractField(i, y, e.target.value)}
                                                                sx={{color: '#000', marginBottom: 1,marginTop: '1rem', marginLeft: 1}} /> : <><span style={{paddingLeft: '0.5rem'}}>{
                                                                    !selectedContractObj[y]?.includes('http') ? selectedContractObj[y] : <a href={selectedContractObj[y]} target="_blank">{selectedContractObj[y]}</a>
                                                                }</span>
                                                                {(!user.contracts[i][y]?.includes('http') && !excludeFromEdit.includes(y)  && user.contracts[i]?.status_maurizon !== 'CARICATO' && role !== 'TEAM LEADER AGENZIA') || role === 'ADMIN' || role === 'OPERATORE BO' ? <IconButton onClick={() => setFieldToEdit(y)}><EditIcon fontSize="small"/></IconButton> : null}

                                                                </>}
                                    </Typography>)}
                                { contractExpand === i && selectedContractObj && Object.keys(selectedContractObj).filter(x => x === 'Competenza').map(y => <Typography variant="body2" color="#3f3f3f" marginTop={2} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <strong>{y}</strong>:

                                    {fieldToEdit === y ? <Select  sx={{color: '#000'}} size="small" value={user.contracts[i]?.Competenza} onChange={(e) => editContractField(i, 'Competenza', e.target.value)}>
                                        <MenuItem value=""></MenuItem>
                                        {arrayMesiAnnoCorrente.map(x => (
                                            <MenuItem value={x}>{x}</MenuItem>
                                        ))}
                                    </Select> : <><span style={{paddingLeft: '0.5rem'}}>{
                                                                !selectedContractObj[y]?.includes('http') ? selectedContractObj[y] : <a href={selectedContractObj[y]} target="_blank">{selectedContractObj[y]}</a>
                                                            }</span>
                                                            {(!user.contracts[i][y]?.includes('http') && !excludeFromEdit.includes(y)  && user.contracts[i].status_maurizon !== 'CARICATO' && role !== 'TEAM LEADER AGENZIA') || role === 'ADMIN' || role === 'OPERATORE BO' ? <IconButton onClick={() => setFieldToEdit(y)}><EditIcon fontSize="small"/></IconButton> : null}

                                                            </>}
                                </Typography>)}
                                { contractExpand === i && Object.keys(selectedContractObj).filter(x => x === 'Nome cognome IBAN' || x === 'IBAN').map(y => <Typography variant="body2" color="#3f3f3f" marginTop={2} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <strong>{y}</strong>:

                                    {fieldToEdit === y ? <TextField variant="outlined"  size="small" InputLabelProps={{
                                                                                                        sx: {
                                                                                                            color: "#444",

                                                                                                        },
                                                                                                        shrink: true
                                                                                                    }}
                                                            value={selectedContractObj[y]}
                                                            inputProps={{style: {color: '#000'}}}
                                                            style={{color: '#000'}}
                                                            onChange={(e) => editContractField(i, y, e.target.value)}
                                                            sx={{color: '#000', marginBottom: 1,marginTop: '1rem', marginLeft: 1}} /> : <><span style={{paddingLeft: '0.5rem'}}>{
                                                                !selectedContractObj[y]?.includes('http') ? selectedContractObj[y] : <a href={selectedContractObj[y]} target="_blank">{selectedContractObj[y]}</a>
                                                            }</span>
                                                            {(!user.contracts[i][y]?.includes('http') && !excludeFromEdit.includes(y)  && user.contracts[i].status_maurizon !== 'CARICATO' && role !== 'TEAM LEADER AGENZIA') || role === 'ADMIN' || role === 'OPERATORE BO' ? <IconButton onClick={() => setFieldToEdit(y)}><EditIcon fontSize="small"/></IconButton> : null}

                                                            </>}
                                </Typography>)}
                                { contractExpand === i && selectedContractObj &&
                                    Object.keys(selectedContractObj).filter(x => selectedContractObj[x] || x === 'Plico').map(y =>
                                        !excludeFromView.includes(y) && <Typography variant="body2" color="#3f3f3f" marginTop={2} sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            <strong>{y}</strong>:

                                            {fieldToEdit === y ? <TextField variant="outlined"  size="small" InputLabelProps={{
                                                                                                                sx: {
                                                                                                                    color: "#444",

                                                                                                                },
                                                                                                                shrink: true
                                                                                                            }}
                                                                    value={user.contracts[i][y]}
                                                                    inputProps={{style: {color: '#000'}}}
                                                                    style={{color: '#000'}}
                                                                    onChange={(e) => editContractField(i, y, e.target.value)}
                                                                    sx={{color: '#000', marginBottom: 1,marginTop: '1rem', marginLeft: 1}} /> : <><span style={{paddingLeft: '0.5rem'}}>{
                                                                        !selectedContractObj[y]?.includes('http') ? selectedContractObj[y] : <a href={selectedContractObj[y]} target="_blank">{selectedContractObj[y]}</a>
                                                                    }</span>
                                                                    {(!selectedContractObj[y]?.includes('http') && !excludeFromEdit.includes(y)  && user.contracts[i].status_maurizon !== 'CARICATO' && role !== 'TEAM LEADER AGENZIA') || role === 'ADMIN' || role === 'OPERATORE BO' ? <IconButton onClick={() => setFieldToEdit(y)}><EditIcon fontSize="small"/></IconButton> : null}

                                                                    </>}
                                        </Typography>
                                    )
                                }
  
                               
                            <div>
                            { contractExpand === i && (
                                   <Stack direction="row" sx={{my: 3}} alignItems="center">
                                    <Typography variant="body2" color="#3f3f3f" sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}><strong style={{color: '#000'}}>Allegato Plico: &nbsp; &nbsp;</strong></Typography>
                                    <input type="file" onChange={async (e) => {
                                        setLoading(true);
                                        console.log(e.target.files)
                                        if(e.target.files[0].type !== 'application/pdf'){
                                            addAlert({
                                                message: 'Sono accettati solo pdf',
                                                severity: 'error'
                                            });
                                            return;
                                        }
                                        config.update({
                                            Region: ServerConfig.AWS.s3Bucket.Region, 
                                            credentials: {
                                                accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
                                                secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
                                            }});
                                            const s3 = new S3({apiVersion: ServerConfig.AWS.s3Bucket.ApiVersion, credentials: {
                                                accessKeyId: ServerConfig.AWS.s3Bucket.AccessKeyId,
                                                secretAccessKey: ServerConfig.AWS.s3Bucket.SecretKeyId
                                            }});
                                        
                                            try{
                                                const fileBase64 = await fileConverterBase64(e.target.files[0]);
                                                const fileParticles = fileBase64.split(',')[0];
                                                const fileKey = `${moment().valueOf()}.contract-file.${fileParticles.split(';')[0].split('/')[1]}`
                                                const params = {
                                                    Bucket: ServerConfig.AWS.s3Bucket.BucketName,
                                                    Key: fileKey,
                                                    Body: Buffer.from(fileBase64.split(',')[1], 'base64'),
                                                    ContentType: e.target.files[0].type,
                                                    ACL: 'public-read'
                                                };
                                                
                                                const res = await s3.putObject(params).promise();
                                                
                                                user.contracts[i]['Plico Allegato'] = `https://crm-storage-files-no-worm.s3.eu-west-1.amazonaws.com/${fileKey}`;
                                                // await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/plico/${contractExpand.id}`,
                                                // {method: 'PUT', body: JSON.stringify({
                                                //     "Allegato Plico": `https://crm-storage-files-no-worm.s3.eu-west-1.amazonaws.com/${fileKey}`
                                                // })})

                                                addAlert({
                                                    message: 'Caricamento file completato',
                                                    severity: 'success'
                                                })
                                            
                                                // await getLead();

                                                setLoading(false);
                                            }catch(ex){
                                                console.log(ex);
                                                addAlert({
                                                    message: 'Errore server',
                                                    severity: 'error'
                                                })
                                            }
                                    }}/>
                                   </Stack> 
                                   
                                   
                                )}
                            <Typography variant="body2" fontWeight={'bold'} color="#3f3f3f" marginTop={2} sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            Matricola: {user.contracts[i]['assigned']}
                            </Typography>
                            </div>
                            <div style={{display: 'flex'}}>

                            <Typography variant="body2" fontWeight={'bold'} color="#3f3f3f" marginTop={2} sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            Note stato Maurizon:
                            </Typography><TextField variant="outlined"  size="small" InputLabelProps={{
                                                                                                                sx: {
                                                                                                                    color: "#444",

                                                                                                                },
                                                                                                                shrink: true
                                                                                                            }}
                                                                    value={user.contracts[i]['Note stato maurizon']}
                                                                    inputProps={{style: {color: '#000'}}}
                                                                    style={{color: '#000'}}
                                                                    onChange={(e) => editContractField(i, 'Note stato maurizon', e.target.value)}
                                                                    sx={{color: '#000', marginBottom: 1,marginTop: '1rem', marginLeft: 1}} /></div>
                            <div style={{display: 'flex', marginBottom: 12}}>
                                
                                <Typography variant="body2" fontWeight={'bold'} color="#3f3f3f" marginTop={2} sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center'
                                            }}>
                                                Data Invio Contratto:
                                </Typography>
                                &nbsp;
                                <TextField size="small" disabled value={moment(user.contracts[i]['data_invio_contratto']).format('DD/MM/YYYY')} />
                                {/* <LocalizationProvider dateAdapter={AdapterMoment}  adapterLocale="it" disabled>
                                    <DatePicker value={moment(user.contracts[i]['data_invio_contratto'])} onChange={(e) => {
                                        editContractField(i, 'data_invio_contratto', e.format('YYYY-MM-DD'))
                                        }
                                    } renderInput={(props) => <TextField {...props} size="small" sx={{width: 150, mr: 2}}/>} />
                                </LocalizationProvider> */}
                              
                             </div>
                            <div style={{display: 'flex', flexDirection:'row', color: '#000', alignItems: 'center'}}><strong>Stato Maurizon:</strong> 
                            <Select value={user.contracts[i].status_maurizon} displayEmpty disabled={
                                ((user.contracts[i].status_maurizon === 'CARICATO' || role === 'TEAM LEADER AGENZIA' || user.contracts[i].status_maurizon === 'KO' || user.contracts[i].status_maurizon === 'DA CARICARE') 
                                && role !== 'ADMIN' && role !== 'OPERATORE BO')
                                } size="small" sx={{width: 200, color: '#000', ml: 1}} onChange={(e) => editContractField(i, 'status_maurizon', e.target.value)}>
                                <MenuItem value={user.contracts[i].status_maurizon}>{user.contracts[i].status_maurizon} </MenuItem>
                                {role === 'AGENTE' && statuses_agent.map(x => <MenuItem value={x}>{x}</MenuItem>)}
                                {role === 'AGENZIA' && statuses_agency.map(x => <MenuItem value={x}>{x}</MenuItem>)}
                                {(role === 'ADMIN' || role === 'OPERATORE BO') && statuses_admin.map(x => <MenuItem value={x}>{x}</MenuItem>)}
                                {role === 'BO AGENZIA' && statuses_bo_agenzia.map(x => <MenuItem value={x}>{x}</MenuItem>)}
                            </Select></div><br />
                            <LoadingButton loading={loading} variant="contained" color="primary" onClick={async () =>
                            {
                            setLoading(true);
                            try{
                                const contract = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contract/${user.contracts[i].id}`);
                                const payload = {...await GzipHelper.unzip(await contract.json()), ...filterNullValues(user.contracts[i]), operatore: (await AuthHelper.getUserFromIdToken()).UserAttributes.filter(x => x.Name === 'sub')[0].Value.split('-')[0]};
                                delete payload['Azione commerciale'];

                                const res = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/contract/edit/${user.contracts[i].id}`, {
                                    method: 'PUT',
                                    body: JSON.stringify(payload)
                                });
                                addAlert({
                                    message: 'Il contratto è stato modificato',
                                    severity: 'success'
                                });
                                setLoading(false)
                            }catch(ex){
                                console.log('errore', ex);
                                setLoading(false)
                                addAlert({
                                    message: 'Errore server.',
                                    severity: 'error'
                                });
                            }}}>Salva contratto</LoadingButton>
                            <br /><br />
                           {(role === 'ADMIN' || role === 'OPERATORE BO') && <LoadingButton loading={loading} variant="contained" color="secondary" onClick={async () =>
                            { setSelectedContract(user.contracts[i].id); setAssignOpen(true);}}>Assegna contratto</LoadingButton>}

                            <div style={{textAlign: 'center', marginTop: 24}}>
                                    <Button onClick={() => expandContract(i)}>{contractExpand === i ? 'Riduci' : 'Espandi'}</Button>
                                </div>
                            </CardContent>
                        </Card> ): <p>Nessun contratto</p>}

            </TabPanel>
        </Grid>
        <Grid item sx={{
                display: {
                    xs: 'none',
                    md: 'block'
                }

        }} xs={3} md={3}>
            <div style={{display: 'flex',  flexDirection: 'column', background: '#fff', borderLeft: '1px solid rgb(203, 214, 226)', width: '100%', height: '100vh', position: 'sticky', top: 0}}>
            <div style={{display: 'flex', paddingTop: '1rem', paddingRight: '1rem', paddingLeft: '1rem', flexDirection: 'column', textAlign: 'left'}}>
                <Typography fontWeight="bold" color="rgb(46, 63, 80)" fontSize="1.3rem">Interessi</Typography>
                <div style={{display: 'flex', flexDirection: 'row', gap: 8, paddingTop: 12, paddingBottom: 12}}>
                    {/* <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="#3f3f3f" gutterBottom>
                            28/01/2023
                            </Typography>
                            <Typography variant="h5" color="#3f3f3f" component="div">
                            Contratto Fastweb
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="#3f3f3f">
                            Stato: Da inviare
                            </Typography>
                            <Typography variant="body2" color="#3f3f3f">
                            Dati anagrafici non corretti modificare comune di attivazione
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Modifica</Button>
                        </CardActions>
                    </Card> */}
                    {user && user.interests && Object.keys(user.interests).map(x => x !== "id" && x !== 'matricola' && x !== 'assignedAgent' && x !== 'assigned' && x !== 'agency' ? <Chip label={x} sx={{backgroundColor: 'rgb(81, 111, 144)'}} onClick={() => dispatchChipsAction(x)} /> : null)}
                    {/* <Chip label={}/> */}
                </div>
                {/* disabled={!user.privacies?.some(x => x[Object.keys(x)].accepted)} */}
                {/* disabled={!user.privacies?.some(x => x[Object.keys(x)].accepted)} */}
                {/* <Button fullWidth variant="contained" onClick={handleClick} disabled={!(isConsent().some((x) => x === true))} >Aggiorna interessi</Button>  */}
                {/* disabled={!(isConsent().some((x) => x === true))} */}
                <Button fullWidth variant="contained" onClick={handleContractAdd} sx={{mt: 2}} disabled={false}>Aggiungi contratto</Button>

            </div>
            </div>
        </Grid>
      </Grid>);
}

export default GetLead;